import React, { useState} from 'react';
import {InputCheckForNumbers, StringAvatar} from "../helpers/helpers";
import {CustomInput, CustomSelect, EmptyImg, PhotoAddIcon,} from "../helpers/common";
import { useParams} from "react-router-dom";
import {ClientsService} from "../services/ClientsService";

function CustomersView() {
    const {id} = useParams()
    const customer = ClientsService.GetCustomerByUseAsync(id)

    const [balance, setBalance] = useState({
        value: '0',
        modal: false,
        requestIsSent: false,
    })
    const submitBalanceChanges = () => {
        setBalance({
            ...balance,
            requestIsSent: true
        })
        ClientsService.UpdateUserBalance(id, balance.value).then(()=>{
            window.location.reload()
        })
    }
    return (
        <div className='componentMainWrapper'>
            <div className='w-full pb-9'>
                <div className='flex w-full flex-row justify-start items-center gap-7 mb-6'>
                    <h1 className='page__title'>Просмотр клиента</h1>
                </div>
                {customer.loading
                    ?
                    <div className='w-full flex justify-center items-center'>
                        <div className="loader"></div>
                    </div>
                    : !customer.error &&
                    <div className='w-full flex flex-col justify-start items-center rounded-6px shadow-default bg-white'>
                        <div className='w-full p-7 flex justify-start items-start relative'>
                            <div
                                className='personal__avatar flex justify-center items-center w-210px h-210px bg-green rounded-20px mr-6'>
                                {StringAvatar(`${customer.result?.data.first_name} ${customer.result?.data.last_name}`)}
                            </div>
                            <div className='personal__info flex flex-col justify-start items-start'>
                                <div>
                                    <p>{customer.result?.data.first_name} {customer.result?.data.last_name}</p>
                                    <span>Код клиента {customer.result?.data.code}</span>
                                </div>
                                <div>
                                    <p>Почта:</p>
                                    <span>{customer.result?.data.email}</span>
                                </div>
                                <div>
                                    <p>Номер:</p>
                                    <span>{customer.result?.data.phone}</span>
                                </div>
                                <div>
                                    <p>Адрес:</p>
                                    <span>{customer.result?.data.address}</span>
                                </div>
                                <div>
                                    <p>Статус:</p>
                                    <span
                                        style={customer.result?.data.is_verified ? {color: '#19BD81'} : {color: '#DB3737'}}>{customer.result?.data.is_verified ? 'Аккаунт подтвержден' : 'Аккаунт не подтвержден'}</span>
                                </div>
                                <div>
                                    <p>Баланс:</p>
                                    <span style={{color: 'green'}}>{customer.result?.data.rounded_balance} сом</span>
                                    <button className='submit-button_blue h-10 ml-5' onClick={()=>{setBalance({...balance, modal:true})}}>
                                        Пополнить
                                    </button>
                                </div>
                            </div>
                            <div style={{width: '200px'}} className=' absolute top-7 right-7 flex flex-col gap-1'>
                                <CustomSelect
                                    className='default-select default-select_with-border'
                                    required={true}
                                    value={`${customer.result?.data.is_verified}`}
                                    onChange={(event)=>{
                                        ClientsService.SetClientVerified(id, event.target.value === 'true', customer.result?.data.is_vip_user).then(()=>{
                                            window.location.reload()
                                        })
                                    }}
                                >
                                    <option value="true" style={{color: 'green'}}>Подтвержден</option>
                                    <option value="false" style={{color: 'red'}}>Не подтвержден</option>
                                </CustomSelect>
                                <CustomSelect
                                    className='default-select default-select_with-border'
                                    required={true}
                                    value={`${customer.result?.data.is_vip_user}`}
                                    onChange={(event)=>{
                                        ClientsService.SetClientVerified(id, customer.result?.data.is_verified, event.target.value === 'true').then(()=>{
                                            window.location.reload()
                                        })
                                    }}
                                >
                                    <option value="true" style={{color: 'green'}}>Особый тариф</option>
                                    <option value="false" style={{color: 'red'}}>Базовый тариф</option>
                                </CustomSelect>
                            </div>
                        </div>
                        <div className='w-full flex flex-col justify-start items-start'>
                            <div className='passport__title w-full px-7 pt-5 pb-3'>
                                <span>Паспортные данные</span>
                            </div>
                            <div className='passport__body w-full px-7 pt-5 pb-7'>
                                <div className='passport-body__item'>
                                    <span>Лицевая сторона</span>
                                    <div>
                                        {customer.result?.data.passport_front_side
                                            ? <img src={customer.result?.data.passport_front_side} alt="Лицевая сторона"/>
                                            : <EmptyImg/>
                                        }
                                    </div>
                                </div>
                                <div className='passport-body__item'>
                                    <span>Обратная сторона</span>
                                    <div>
                                        {customer.result?.data.passport_back_side
                                            ? <img src={customer.result?.data.passport_back_side} alt="Обратная сторона"/>
                                            : <EmptyImg/>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        {balance.modal &&
                            <div onClick={(e) => {
                                e.preventDefault()
                                e.stopPropagation()
                                setBalance({...balance,modal: false})
                            }}
                                 className='personal-password-edit__modal fixed top-0 left-0 z-999 w-full h-full flex justify-center items-center bg-white-opacity'
                            >
                                <form onSubmit={(e) => {e.preventDefault(); submitBalanceChanges()}}
                                      onClick={(e) => {e.stopPropagation()}}
                                      style={{maxWidth: '620px', width: '100%'}}
                                      className='relative flex flex-col bg-white justify-start items-center px-14 py-8 rounded-10px shadow-default'
                                >
                                    <div onClick={()=>{setBalance({...balance,modal: false})}}
                                         className='absolute right-6 top-8 rotate-45 cursor-pointer rounded-full flex justify-center items-center hover:bg-icon-button-hover'
                                         style={{width: '30px', height: '30px'}}>
                                        <PhotoAddIcon/>
                                    </div>
                                    <h1 className='mb-7'>Пополнение баланса</h1>
                                    <CustomInput
                                        className='text-input text-input_40px text-input_with-border'
                                        type='number'
                                        placeholder='Наличными:'
                                        required={true}
                                        label='Наличными:'
                                        onChange={(e)=>{setBalance({...balance, value: e.target.value})}}
                                        value={balance.value}
                                    />

                                    <button type='submit' disabled={balance.requestIsSent} className='submit-button_blue h-10'>
                                        {balance.requestIsSent
                                            ? <div className="loader"></div>
                                            : <span>Пополнить</span>
                                        }
                                    </button>
                                </form>
                            </div>
                        }
                    </div>
                }
            </div>
        </div>
    );
}

export default CustomersView;


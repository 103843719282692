const defaultState = {
    user:{
        id: '',
        first_name: 'User',
        last_name: 'User',
        date_joined: '',
        email: '',
        group: '',
    }
}

export const personalReducer = (state = defaultState, action: any) =>{
    switch (action.type){
        case "UPDATE_DATA":
            return {
                ...state, user:{...state.user, ...action.payload}
            }
        default: return state
    }
}
import React, {useEffect, useState} from 'react';
import {useNavigate} from "react-router-dom";
import {
    CustomInput,
    CustomPagination,
    CustomTable,
    FilterSearchIcon, PhotoAddIcon,
} from "../helpers/common";
import moment from 'moment'
import {OrdersService} from "../services/OrdersService";
import {Pagination} from "@mui/material";

function Sorting() {
    const navigate = useNavigate()
    const [validation, setValidation] = useState({
        modal: false,
        weight: '',
    })
    const [filterValue, setFilterValue] = React.useState<any>({
        page: 1,
        count: 1,
        search: '',
        requestIsSend: false,
    })
    const mainTableColumns: any[] = [
        { field: 'id', headerName: 'ID посылки', hide: true},
        { field: 'code', headerName: 'Код клиента',  width: '80px'},
        { field: 'full_name', headerName: 'ФИО'},
        { field: 'tracking_number', headerName: 'Трек-код',},
        { field: 'comment', headerName: 'Комментарий'},
    ];
    const [selectedRowsId, setSelectedRowsId] = useState<any>([])
    const [mainTableData, setMainTableData] = useState<any>([])
    const sortingList = OrdersService.GetSortingList(filterValue)
    const sendSelectedOrdersAndPrint = () =>{
        setFilterValue({
            ...filterValue,
            requestIsSend: true,
        })
        OrdersService.SortingOrdersSendAndPrint(selectedRowsId, true, validation.weight).then((res)=>{
            // window.open(res.data.sticker_link)
            setSelectedRowsId([])
            setFilterValue({
                ...filterValue,
                requestIsSend: false,
            })
            setValidation({
                ...validation,
                modal: false,
                weight: '0',
            })
        }).catch((err)=>{
            console.log(err)
            setFilterValue({
                ...filterValue,
                requestIsSend: false,
            })
            setValidation({
                ...validation,
                modal: false,
                weight: '0',
            })
        })
    }
    useEffect(()=>{
        if(!sortingList.loading && !sortingList.error){
            setFilterValue((prevState: any)=>({
                ...prevState,
                count: sortingList.result?.data.total_pages,
                page: sortingList.result?.data.current_page,
            }))
            const arr: any[] = []
            for (let i=0;i< sortingList.result?.data.results.length;i++){
                arr[i] = {
                    id: sortingList.result?.data.results[i].id,
                    full_name: sortingList.result?.data.results[i].user !== null ? sortingList.result?.data.results[i].user.full_name : sortingList.result?.data.results[i].attribute,
                    code: sortingList.result?.data.results[i].user !== null ? sortingList.result?.data.results[i].user.code : '',
                    tracking_number: sortingList.result?.data.results[i].tracking_number,
                    created_at: moment(sortingList.result?.data.results[i].created_at).format('DD.MM.YYYY').toString(),
                    comment: sortingList.result?.data.results[i].comment,
                }
            }
            setMainTableData(arr)
        }
    },[sortingList.loading])

    const closeModal = (event: any) => {
        event.preventDefault()
        event.stopPropagation()
        setValidation({...validation, modal: false, weight: '0'})
    }
    return (
        <div className='componentMainWrapper'>
            <div className='w-full pb-9'>
                <div className='flex w-full flex-row justify-between items-center gap-7 mb-6'>
                    <h1 className='page__title'>Сортировка</h1>
                    <div className='submit-button_blue h-10'
                         onClick={()=>{navigate('/sortingAdd')}}
                    >
                        Добавить посылку
                    </div>
                </div>

                <div className='flex w-full flex-row justify-between items-center mb-6'>
                    <div className='flex w-fit flex-row justify-start items-center gap-5'>

                    </div>
                    <div className='flex w-fit flex-row justify-start items-center'>
                        <CustomInput
                            className='filter-input_search'
                            type='text'
                            placeholder='Введите код клиента'
                            value={filterValue.search}
                            onChange={(event)=>{setFilterValue({
                                ...filterValue,
                                search: event.target.value,
                                page: 1,
                            })}}
                            inputProps={
                                <div className='text-input_icon_button'>
                                    <FilterSearchIcon/>
                                </div>
                            }
                        />
                    </div>
                </div>

                <CustomTable
                    columns={mainTableColumns}
                    rows={mainTableData}
                    checkboxSelection={true}
                    selectedRowsId={selectedRowsId}
                    onSelectAll={(params)=>params.code !== ''}
                    isRowSelectDisabled={(params)=> params.code === ''}
                    loading={sortingList.loading}
                    multipleSelect={filterValue.search !== ''}
                    onSelectionModelChange={(selectionModel: [])=>setSelectedRowsId(selectionModel)}
                    footer={
                        <div className="page-table__footer flex flex-col justify-start items-center p-2.5">
                            <div className='w-full flex justify-between items-center mt-2.5 mr-2.5'>
                                <div>

                                </div>
                                <div className='flex justify-center items-center gap-5'>
                                    {selectedRowsId.length > 0 &&
                                        <span className='selected-orders-count'>Выбрано {selectedRowsId.length}</span>
                                    }
                                    <button disabled={filterValue.search === '' || selectedRowsId.length === 0}
                                            className='submit-button_blue submit-button_blue_small h-7'
                                            onClick={()=>{setValidation({...validation, modal: true})}}
                                    >
                                        <span>Отправить</span>
                                    </button>
                                </div>
                            </div>
                            <Pagination
                                color="primary"
                                count={filterValue.count}
                                page={filterValue.page}
                                onChange={(event,value)=>{
                                    setFilterValue({
                                        ...filterValue,
                                        page: value,
                                    })
                                }}
                            />
                        </div>
                    }
                />
                {validation.modal &&
                    <div onClick={closeModal}
                         className='personal-password-edit__modal p-20 fixed top-0 left-0 z-999 w-full h-full flex justify-center items-center bg-white-opacity'
                    >
                        <form onSubmit={(e) => {e.preventDefault(); sendSelectedOrdersAndPrint()}}
                              onClick={(e) => {e.stopPropagation()}}
                              style={{maxWidth: '620px', width: '100%'}}
                              className='h-full relative flex flex-col bg-white justify-start items-center px-20 py-8 rounded-10px shadow-default'
                        >
                            <div onClick={closeModal}
                                 className='absolute right-6 top-8 rotate-45 cursor-pointer rounded-full flex justify-center items-center hover:bg-icon-button-hover'
                                 style={{width: '30px', height: '30px'}}>
                                <PhotoAddIcon/>
                            </div>
                            <h1 className='mb-7'>
                                Добавление веса
                            </h1>
                            <div className='w-full flex flex-col overflow-scroll h-full'>
                                <CustomInput
                                    className='text-input text-input_with-border'
                                    type='number'
                                    placeholder='Вес'
                                    required={true}
                                    label='Вес'
                                    value={validation.weight}
                                    onChange={(event)=>{setValidation({
                                        ...validation,
                                        weight: event.target.value,
                                    })}}
                                />
                            </div>
                            <button disabled={selectedRowsId.length === 0 || filterValue.requestIsSend}
                                    className='submit-button_blue h-10'
                                    type='submit'
                            >
                                {filterValue.requestIsSend
                                    ? <div style={{width: '28px', height: '28px'}} className="loader"></div>
                                    : <span>Отправить</span>
                                }
                            </button>
                        </form>
                    </div>
                }
            </div>
        </div>
    );
}

export default Sorting;


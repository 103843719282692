import {$axios} from "../http";
import {useAsync} from "react-async-hook";
import {CreateCleanSearchParams} from "../helpers/helpers";
import {createSearchParams} from "react-router-dom";

export const NotificationService = {
    async CreateNotification(notificationInfo: any) {
        return await $axios.post('/admin/notifications/', {...notificationInfo})
    },
    async DeleteSelectedNotifications(arrOfId: []) {
        return await $axios.post('/notifications/bulk/update/delete/', {
            notification_ids: arrOfId,
            flag: 'delete',
        })
    },
    async DeleteNotification(id: string) {
        return await $axios.delete('/admin/notifications/' + `${id}/`)
    },
    GetNotifications(searchParamsObj?: any) {
        return useAsync(async () => {
            return await $axios.get('/admin/notifications/' + CreateCleanSearchParams(searchParamsObj))
        }, [`?${createSearchParams(searchParamsObj)}`])
    },
    GetNotification(id: string | undefined) {
        return useAsync(async () => {
            return await $axios.get('/admin/notifications/' + `${id}`);
        }, [id])
    }
}
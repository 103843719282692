import React, {useState} from 'react';
import { CustomInput} from "../helpers/common";
import moment from "moment/moment";
import {IssueService} from "../services/IssueService";

function Issue() {
    const [filterValue, setFilterValue] = useState({
        search: '',
        requestIsSend: false,
    })
    const cheque = IssueService.GetIssueCheque(filterValue)
    const handleSetIssued = (id: string)=>{
        setFilterValue({
            ...filterValue,
            requestIsSend: true,
        })
        IssueService.SetChequeIssued(id).then(()=>{
            setFilterValue({
                ...filterValue,
                requestIsSend: false,
            })
        }).catch((err)=>{
            console.log(err)
        })
    }
    return (
        <div className='componentMainWrapper'>
            <div className='w-full pb-9'>
                <div className='flex w-full flex-row justify-start items-center gap-7 mb-6'>
                    <h1 className='page__title'>Выдача</h1>
                </div>

                <div className='flex w-full mb-10'>
                    <CustomInput
                        className='text-input'
                        type='text'
                        placeholder='Отсканируйте штрих-код или введите номер чека'
                        value={filterValue.search}
                        onChange={(event)=>{
                            setFilterValue({
                                ...filterValue,
                                search: event.target.value,
                            })
                        }}
                    />
                </div>

                {cheque.loading
                    ? <div className='w-full flex justify-center'>
                        <div className="loader"></div>
                    </div>
                    : !cheque.error && cheque.result?.data.length > 0
                        ? <div className='rounded-10px shadow-default bg-white overflow-hidden w-full flex flex-col justify-start items-center'>
                            <div className='form-add__header bg-form-header w-full px-6 py-3 flex justify-between items-center'>
                                <div className='flex justify-start items-center gap-8'>
                                    <p>
                                        Чек № {cheque.result?.data[0].transaction_id} / Код клиента - {cheque.result?.data[0].user.code}
                                    </p>
                                </div>
                            </div>
                            {cheque.result?.data[0].orders_group.orders.map((order:any, i:number)=>(
                                <div key={i} className='orderView-container w-full flex justify-start items-start px-6 pt-5 pb-4 flex-wrap'>
                                    <h1 className='py-3 mb-5 w-full'>Информация о посылке</h1>
                                    <div className='w-32'>
                                        <p>ID посылки</p>
                                        <span>{order.id}</span>
                                    </div>
                                    <div>
                                        <p>Трек-номер</p>
                                        <span>{order.tracking_number}</span>
                                    </div>
                                </div>
                            ))}
                            <div className='orderView-container w-full flex justify-between items-start px-6 pt-5 pb-4 flex-wrap'>
                                <h1 className='py-3 mb-5 w-full'>Информация о чеке</h1>
                                <div>
                                    <p>Номер чека</p>
                                    <span>{cheque.result?.data[0].transaction_id}</span>
                                </div>
                                <div>
                                    <p>Дата создания</p>
                                    <span>{moment(cheque.result?.data[0].created_at).format('DD.MM.YYYY').toString()}</span>
                                </div>
                                <div>
                                    <p>Способ оплаты</p>
                                    <span>{cheque.result?.data[0].payment_method.name}</span>
                                </div>
                                <div>
                                    <p>Итоговая сумма</p>
                                    <span>{cheque.result?.data[0].amount} сом</span>
                                </div>
                                <div>
                                    <p>Статус</p>
                                    <span>{cheque.result?.data[0].issued ? 'Выдан' : 'Готово к выдаче'}</span>
                                </div>
                            </div>
                            <div className='w-full flex justify-end items-start px-6 pt-5 pb-4 flex-wrap'>
                                {!cheque.result?.data[0].issued &&
                                    <button disabled={filterValue.requestIsSend} type='submit' className='submit-button_blue h-10'
                                            onClick={()=>{handleSetIssued(cheque.result?.data[0].id)}}
                                    >
                                        {filterValue.requestIsSend
                                            ?<div style={{width: '30px', height: '30px'}} className="loader"></div>
                                            : <span>Выдать</span>
                                        }
                                    </button>
                                }
                            </div>
                    </div>
                        : <div className='w-full flex justify-center'>
                            <span>Ничего не найдено...</span>
                        </div>
                }
            </div>
        </div>
    );
}

export default Issue;


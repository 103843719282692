import {$axios} from "../http";
import {useAsync} from "react-async-hook";
import {CreateCleanSearchParams} from "../helpers/helpers";
import {createSearchParams} from "react-router-dom";

interface ransomCreateI {
    ransom_order: {
        tracking_number: string,
        title: string,
        comment: string,
        quantity: string,
        price: string,
        order_total_price: string,
    },
    product_link: string,
    code: string,
    full_name: string,
    payment_type: string,
    currency_value: string,
    currency: string,
    withdrawal_amount: string,
    commission: string,
}

interface ransomUpdateI {
    id: string | undefined,
    ransom_order: {
        tracking_number: string,
        title: string,
        comment: string,
        quantity: string,
        price: string,
        order_total_price: string,
    },
    product_link: string,
    user_id: string,
    full_name: string,
    payment_type: string,
    currency_value: string,
    currency: string,
    withdrawal_amount: string,
    commission: string,
}

interface AddOrderToSortingI {
    order_id: string,
    tracking_number: string,
    comment: string,
    quantity: string,
    code: string,
    full_name: string,
}
interface AddOrderToWarehouseI {
    tracking_number?: string,
    comment?: string,
    quantity?: string,
    code?: string,
    full_name?: string,
}

export const OrdersService = {
    GetLostOrders(searchParamsObj: any) {
        return useAsync(async () => {
            return await $axios.get('/admin/lost/orders/' + CreateCleanSearchParams(searchParamsObj));
        }, [`?${createSearchParams(searchParamsObj)}`])
    },
    async AssignLostOrder(orders: [], user_id: string) {
        return await $axios.post('/admin/assign/lost/order/', {
            orders: orders,
            user: user_id,
        })
    },
    GetOrders(searchParamsObj: any) {
        return useAsync(async () => {
            return await $axios.get('/admin/orders/' + CreateCleanSearchParams(searchParamsObj));
        }, [`?${createSearchParams(searchParamsObj)}`])
    },
    GetOrderStatusButtons() {
        return useAsync(async () => {
            return await $axios.get('/admin/statuses/')
        }, [])
    },
    GetOrder(id: string | undefined) {
        return useAsync(async () => {
            return await $axios.get('/admin/orders/' + `${id}`);
        }, [id])
    },
    GetRansoms(searchParamsObj: any) {
        return useAsync(async () => {
            return await $axios.get('/admin/ransom/' + CreateCleanSearchParams(searchParamsObj))
        }, [`?${createSearchParams(searchParamsObj)}`])
    },
    async DeleteRansom(id: string) {
        return await $axios.delete('/admin/ransom/' + `${id}/`)
    },
    async CreateRansom(ransomInfo: ransomCreateI) {
        return await $axios.post('/admin/ransom/', {...ransomInfo})
    },
    GetRansom(id: string | undefined) {
        return useAsync(async () => {
            return await $axios.get('/admin/ransom/' + `${id}/`);
        }, [id])
    },
    async UpdateRansom(ransomInfo: ransomUpdateI) {
        return await $axios.put('/admin/ransom/' + `${ransomInfo.id}/`, {...ransomInfo})
    },
    GetSortingList(searchParamsObj: any) {
        return useAsync(async () => {
            return await $axios.get('/admin/sorting/orders/' + CreateCleanSearchParams(searchParamsObj))
        }, [`?${createSearchParams(searchParamsObj)}`])
    },
    async SortingOrdersSendAndPrint(orders: [], send: boolean, weight: string) {
        return await $axios.post('/admin/sorting/orders/print/sticker/', {orders: orders, send: send, total_weight: weight})
    },
    async UpdateOrderToSorting(orderInfo: AddOrderToSortingI) {
        return await $axios.put('/admin/sorting/orders/' + `${orderInfo.order_id}/`, {...orderInfo})
    },
    GetSortingOrderBySearch(searchParamsObj: any) {
        return useAsync(async () => {
            return await $axios.get('/admin/sorting/search/order/' + CreateCleanSearchParams(searchParamsObj));
        }, [`?${createSearchParams(searchParamsObj)}`])
    },
    GetTotalWeightOfOrders(searchParamsObj: any) {
        return useAsync(async () => {
            return await $axios.get('/admin/sorting/shipment/history/' + CreateCleanSearchParams(searchParamsObj))
        }, [`?${createSearchParams(searchParamsObj)}`])
    },
    GetTotalWeightOfOrdersGroups(id: any, searchParamsObj: any) {
        return useAsync(async () => {
            return await $axios.get('/admin/sorting/shipment/history/' + `${id}/` + CreateCleanSearchParams(searchParamsObj))
        }, [`?${createSearchParams(searchParamsObj)}`])
    },
    GetOrdersInWarehouse(searchParamsObj: any) {
        return useAsync(async () => {
            return await $axios.get('/admin/warehouse/orders/' + CreateCleanSearchParams(searchParamsObj))
        }, [`?${createSearchParams(searchParamsObj)}`])
    },
    async WarehouseOrdersSend(orders: []) {
        return await $axios.post('/admin/warehouse/orders/send/', {orders: orders})
    },

    GetWarehouse(searchParamsObj: any) {
        return useAsync(async () => {
            return await $axios.get('/admin/warehouse/search/order/' + CreateCleanSearchParams(searchParamsObj));
        }, [`?${createSearchParams(searchParamsObj)}`])
    },
    async AddOrderToWarehouse(orderInfo: AddOrderToWarehouseI) {
        const newObj = {
            ...orderInfo,
            full_name: orderInfo.code === '' ? 'Утерянная посылка' : orderInfo.full_name
        }
        return await $axios.post('/admin/warehouse/order/add/', {...newObj})
    },
    async DeleteOrderFromWarehouse(id: any) {
        return await $axios.delete(`/admin/warehouse/orders/destroy/${id}/`)
    }

}
import React, {useEffect, useState} from 'react';
import {
    CustomInput,
    CustomPagination,
    CustomTable,
    FilterSearchIcon,
} from "../helpers/common";
import moment from "moment/moment";
import {useNavigate, useParams} from "react-router-dom";
import {HistoryService} from "../services/HistoryService";
import {Pagination} from "@mui/material";

function ShipmentsHistoryOrders() {
    const {id} = useParams()
    const navigate = useNavigate()
    const [filterValue, setFilterValue] = React.useState<any>({
        full_name: '',
        user: '',
        page: 1,
        count: 1,
        search: '',
        update_request: false,
        requestIsSend: false,
    })
    const mainTableColumns: any[] = [
        { field: 'id', headerName: 'ID посылки', width: '80px', hide: true},
        { field: 'code', headerName: 'Код клиента', width: '80px'},
        { field: 'full_name', headerName: 'ФИО'},
        { field: 'tracking_number', headerName: 'Трек-код',},
        { field: 'weight', headerName: 'Вес', width: '80px'},
        { field: 'comment', headerName: 'Комментарий'},
        { field: 'status', headerName: 'Статус', width: '70px', renderCell: (params: any) => {
                return (
                    <div className="flex justify-center items-center gap-5">
                        <img src={params.status_icon} alt="icon" style={{width:'22px', height:'22px'}}/>
                    </div>
                )
            }},
        { field: 'status_icon', headerName: '', hide: true,},
    ];
    const [mainTableData, setMainTableData] = useState<any>([])

    const orders = HistoryService.GetShipmentHistoryOrders(id, filterValue)

    useEffect(()=>{
        if(!orders.loading && !orders.error){
            setFilterValue((prevState: any)=>({
                ...prevState,
                count: orders.result?.data.total_pages,
                page: orders.result?.data.current_page,
            }))
            const arr: any[] = []
            for (let i=0;i< orders.result?.data.results.length;i++){
                arr[i] = {
                    id: orders.result?.data.results[i].id,
                    code: orders.result?.data.results[i].user !== null ? orders.result?.data.results[i].user.code : '',
                    full_name: orders.result?.data.results[i].user !== null ? orders.result?.data.results[i].user.full_name : orders.result?.data.results[i].attribute,
                    tracking_number: orders.result?.data.results[i].tracking_number,
                    weight: orders.result?.data.results[i].weight,
                    created_at: moment(orders.result?.data.results[i].created_at).format('DD.MM.YYYY').toString(),
                    comment: orders.result?.data.results[i].comment,
                    status_icon: orders.result?.data.results[i].status.icon,
                }
            }
            setMainTableData(arr)
        }
    },[orders.loading])
    return (
        <div className='componentMainWrapper'>
            <div className='w-full pb-9'>
                <div className='flex w-full flex-row justify-between items-center gap-7 mb-6'>
                    <h1 className='page__title'>История посылок</h1>
                </div>

                <div className='flex w-full flex-row justify-between items-center mb-6'>
                    <div className='flex w-fit flex-row justify-start items-center gap-5'>
                    </div>
                    <div className='flex w-fit flex-row justify-start items-center'>
                        <CustomInput
                            className='filter-input_search'
                            type='text'
                            placeholder='Введите номер трека или посылки'
                            value={filterValue.search}
                            onChange={(event)=>{setFilterValue({
                                ...filterValue,
                                search: event.target.value,
                                page: 1,
                            })}}
                            inputProps={
                                <div className='text-input_icon_button'>
                                    <FilterSearchIcon/>
                                </div>
                            }
                        />
                    </div>
                </div>

                <CustomTable
                    columns={mainTableColumns}
                    rows={mainTableData}
                    checkboxSelection={false}
                    loading={orders.loading}
                    onRowDoubleClick={(params)=>{navigate(`/orderView/${params.id}/`)}}
                    footer={
                        <div className="page-table__footer flex flex-col justify-start items-center p-2.5">
                            <Pagination
                                color="primary"
                                count={filterValue.count}
                                page={filterValue.page}
                                onChange={(event,value)=>{
                                    setFilterValue({
                                        ...filterValue,
                                        page: value,
                                    })
                                }}
                            />
                        </div>
                    }
                />
            </div>
        </div>
    );
}

export default ShipmentsHistoryOrders;


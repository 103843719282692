import React, {useEffect} from 'react';
import headerLogoImg from '../assets/images/image 48.svg'
import {
    SideBarBellIcon,
    SideBarBoxOfficeIcon, SideBarDeliveryHistoryIcon,
    SideBarExitIcon,
    SideBarHomeIcon,
    SideBarIssueIcon,
    SideBarLostOrdersIcon,
    SideBarOrdersIcon,
    SideBarPaymentHistoryIcon,
    SideBarRansomIcon,
    SideBarSortingIcon,
    SideBarStaffIcon,
    SideBarUserIcon,
    SideBarWarehouseIcon
} from "../helpers/common";
import {Link, useLocation, useNavigate} from "react-router-dom";
import {getCookie, removeCookie} from "typescript-cookie";
import {useDispatch, useSelector} from "react-redux";
import {StringAvatar} from "../helpers/helpers";
import {AuthService} from "../services/AuthService";

interface userI {
    id: number | null | undefined,
    first_name: string | null | undefined,
    last_name: string | null | undefined,
    date_joined: string | null | undefined,
    email: string | null | undefined,
    group: string | null | undefined,
}
function Header() {
    const group: string | undefined = getCookie('goldMark_group')
    const navigate = useNavigate()
    const user = useSelector((state: any) => state.user)
    const location = useLocation()
    const dispatch = useDispatch()
    const setUser = ({date_joined, group, id, first_name, last_name, email}:userI ) =>{
        dispatch({
            type:"UPDATE_DATA",
            payload:{
                id: (typeof id === 'undefined' ? '' : `${id}` ),
                first_name: (typeof first_name === 'undefined' ? '' : first_name ),
                last_name: (typeof last_name === 'undefined' ? '' : last_name ),
                date_joined: (typeof date_joined === 'undefined' ? '' : date_joined ),
                email: (typeof email === 'undefined' ? '' : email ),
                group: (typeof group === 'undefined' ? '' : group ),
            }
        })
    }
    useEffect(()=>{
        AuthService.GetPersonalInfo().then((res)=>{
            setUser({
                id: res.data.user.id,
                first_name: res.data.user.first_name,
                last_name: res.data.user.last_name,
                date_joined: res.data.user.date_joined,
                email: res.data.user.email,
                group: res.data.user.groups[0].name,
            })
        })
    },[])
    return (
        <div className='header' id='header'>
            <div className="header__mainBar">
                <p className="header-mainBar__user-greeting-text">
                    Добро пожаловать, {user.first_name}
                </p>
                <div className='flex justify-between items-center gap-7'>
                    <div className='header__avatar w-50px h-50px flex justify-center items-center bg-green rounded-full'>
                        {StringAvatar(`${user.first_name} ${user.last_name}`)}
                    </div>
                </div>
            </div>
            <div className="header__sideBar flex flex-col justify-start items-center pt-4 pb-32">
                <img src={headerLogoImg} alt="logo" className="header-sideBar__logo mb-12"/>
                <div className="header-sideBar__links">
                    <div className='w-full h-full overflow-scroll'>
                        <Link to='/' className={`header-sideBar__link ${location.pathname === '/' ? 'header-sideBar__link_active' : ''}`}>
                            <SideBarHomeIcon/><span>Главная</span>
                        </Link>
                        {group && (group === 'Администратор') &&
                            <Link to='/personal' className={`header-sideBar__link ${location.pathname.includes('/personal') ? 'header-sideBar__link_active' : ''}`}>
                                <SideBarStaffIcon/><span>Сотрудники</span>
                            </Link>
                        }
                        {group && (group === 'Администратор' || group === 'Менеджер' || group === 'Складовщик') &&
                            <Link to='/orders' className={`header-sideBar__link ${location.pathname.includes('/orders') || location.pathname.includes('/orderView') ? 'header-sideBar__link_active' : ''}`}>
                                <SideBarOrdersIcon/><span>Посылки</span>
                            </Link>
                        }
                        {group && (group === 'Администратор' || group === 'Менеджер' || group === 'Кассир') &&
                            <Link to='/customers' className={`header-sideBar__link ${location.pathname.includes('/customer') || location.pathname.includes('/customerView') ? 'header-sideBar__link_active' : ''}`}>
                                <SideBarUserIcon/><span>Клиенты</span>
                            </Link>
                        }
                        {group && (group === 'Администратор' || group === 'Складовщик') &&
                            <Link to='/warehouse' className={`header-sideBar__link ${location.pathname.includes('/warehouse') || location.pathname.includes('/warehouseAdd') ? 'header-sideBar__link_active' : ''}`}>
                                <SideBarWarehouseIcon/><span>Склад</span>
                            </Link>
                        }
                        {group && (group === 'Администратор' || group === 'Сортировщик') &&
                            <Link to='/sorting' className={`header-sideBar__link ${location.pathname.includes('/sorting') || location.pathname.includes('/sortingAdd') ? 'header-sideBar__link_active' : ''}`}>
                                <SideBarSortingIcon/><span>Сортировка</span>
                            </Link>
                        }
                        {group && (group === 'Администратор' || group === 'Менеджер') &&
                            <Link to='/ransom' className={`header-sideBar__link ${location.pathname.includes('/ransom') || location.pathname.includes('/ransomAdd') ? 'header-sideBar__link_active' : ''}`}>
                                <SideBarRansomIcon/><span>Выкуп</span>
                            </Link>
                        }
                        {group && (group === 'Администратор' || group === 'Сортировщик') &&
                            <Link to='/issue' className={`header-sideBar__link ${location.pathname.includes('/issue') ? 'header-sideBar__link_active' : ''}`}>
                                <SideBarIssueIcon/><span>Выдача</span>
                            </Link>
                        }
                        {group && (group === 'Администратор' || group === 'Менеджер') &&
                            <Link to='/lostOrders' className={`header-sideBar__link ${location.pathname.includes('/lostOrders') ? 'header-sideBar__link_active' : ''}`}>
                                <SideBarLostOrdersIcon/><span>Неизвестные посылки</span>
                            </Link>
                        }
                        {group && (group === 'Администратор' || group === 'Кассир') &&
                            <Link to='/boxOffice' className={`header-sideBar__link ${location.pathname.includes('/boxOffice') ? 'header-sideBar__link_active' : ''}`}>
                                <SideBarBoxOfficeIcon/><span>Касса</span>
                            </Link>
                        }
                        {group && (group === 'Администратор' || group === 'Менеджер') &&
                            <Link to='/notifications' className={`header-sideBar__link ${location.pathname.includes('/notifications') || location.pathname.includes('/notificationAdd') || location.pathname.includes('/notificationView') ? 'header-sideBar__link_active' : ''}`}>
                                <SideBarBellIcon/><span>Уведомления</span>
                            </Link>
                        }
                        {group && group === 'Администратор' &&
                            <Link to='/paymentHistory' className={`header-sideBar__link ${ location.pathname.includes('/paymentHistory') ? 'header-sideBar__link_active' : ''}`}>
                                <SideBarPaymentHistoryIcon/><span>История оплаты</span>
                            </Link>
                        }
                        {group && (group === 'Администратор' || group === 'Складовщик') &&
                            <Link to='/totalWeightOfOrders' className={`header-sideBar__link ${ location.pathname.includes('/totalWeightOfOrders') ? 'header-sideBar__link_active' : ''}`}>
                                <SideBarPaymentHistoryIcon/><span>Прибывшие посылки</span>
                            </Link>
                        }
                        {group && (group === 'Администратор' || group === 'Складовщик')  &&
                            <Link to='/shipmentHistory' className={`header-sideBar__link ${ location.pathname.includes('/shipmentHistory') ? 'header-sideBar__link_active' : ''}`}>
                                <SideBarDeliveryHistoryIcon/><span>История отправок</span>
                            </Link>
                        }
                        {group && (group === 'Администратор' || group === 'Менеджер') &&
                            <Link to='/balanceAddHistory' className={`header-sideBar__link ${ location.pathname.includes('/balanceAddHistory') ? 'header-sideBar__link_active' : ''}`}>
                                <SideBarDeliveryHistoryIcon/><span>История пополнений</span>
                            </Link>
                        }
                    </div>
                    <div className="header-sideBar__link header-sideBar__link_exit" onClick={()=>{
                        removeCookie('goldMark_admin_access_token')
                        removeCookie('goldMark_admin_refresh_token')
                        navigate('/auth')
                    }}>
                        <SideBarExitIcon/><span>Выход</span>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Header;
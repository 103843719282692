import {$axios} from "../http";
import {useAsync} from "react-async-hook";

interface CurrencyI {
    id: string,
    code: string,
    value: string,
    name: string,
}

export const CurrencyService = {
    GetCurrencies(updateRequest?: boolean) {
        return useAsync(async () => {
            return await $axios.get('/currencies/')
        }, [updateRequest])
    },
    async UpdateCurrency(currency: CurrencyI) {
        return await $axios.put('/currencies/' + `${currency.id}/`, currency)
    }
}
import React from 'react';
import {useNavigate, useParams} from "react-router-dom";
import {CalendarIcon, RollBackIcon} from "../helpers/common";
import moment from "moment";
import {OrdersService} from "../services/OrdersService";

function OrderView() {
    const navigate = useNavigate()
    const {id} = useParams()
    const order = OrdersService.GetOrder(id)
    return (
        <div className='componentMainWrapper'>
            <div className='w-full pb-9'>
                <div className='flex w-full flex-row justify-start items-center gap-7 mb-6'>
                    <h1 className='page__title'>Просмотр посылки</h1>
                </div>
                {order.loading
                    ?
                    <div className='w-full flex justify-center items-center'>
                        <div className="loader"></div>
                    </div>
                    :
                    <div className='rounded-10px shadow-default bg-white overflow-hidden w-full flex flex-col justify-start items-center'>
                        <div className='form-add__header bg-form-header w-full px-6 py-3 flex justify-between items-center'>
                            <div className='flex justify-start items-center gap-8'>
                                <div className='form-add__rollBack-icon' onClick={()=>{navigate('/orders')}}>
                                    <RollBackIcon/>
                                </div>
                                <p>
                                    Посылка № {order.result?.data.id}
                                </p>
                            </div>
                            <div className='flex justify-end items-center gap-8'>
                                <span>
                                    {moment(order.result?.data.created_at).format('DD.MM.YYYY').toString()}
                                </span>
                                <CalendarIcon/>
                            </div>
                        </div>
                        <div className='orderView-container w-full flex justify-between items-start px-6 pt-5 pb-4 flex-wrap'>
                            <h1 className='py-3 mb-5 w-full'>Информация пользователя</h1>
                            <div>
                                <p>Получатель</p>
                                <span>{order.result?.data.user !== null ? order.result?.data.user.full_name : order.result?.data.attribute}</span>
                            </div>
                            <div>
                                <p>ID</p>
                                <span>{order.result?.data.user?.code}</span>
                            </div>
                            <div>
                                <p>Номер получателя</p>
                                <span>{order.result?.data.user?.phone}</span>
                            </div>
                            <div>
                                <p>Адрес получателя</p>
                                <span>{order.result?.data.user?.address}</span>
                            </div>
                        </div>
                        <div className='orderView-container w-full flex justify-between items-start px-6 pt-5 pb-4 flex-wrap'>
                            <h1 className='py-3 mb-5 w-full'>Информация посылки</h1>
                            <div>
                                <p>Трек-номер</p>
                                <span>{order.result?.data.tracking_number}</span>
                            </div>
                            <div>
                                <p>Наименование</p>
                                <span>{order.result?.data.title}</span>
                            </div>
                            <div>
                                <p>Количество</p>
                                <span>{order.result?.data.quantity}</span>
                            </div>
                            <div>
                                <p>Цена</p>
                                <span>{order.result?.data.price}</span>
                            </div>

                        </div>
                        <div className='orderView-container_status w-full flex justify-between items-start px-6 pt-5 pb-4 flex-wrap'>
                            <h1 className='py-3 mb-5 w-full'>История статусов</h1>
                            {order.result?.data.status_history.map((status: any, i: number)=>(
                                <div className="orderView-container_statusWrapper" key={i}>
                                    <div>
                                        <p>Статус:</p>
                                        <span>{status.status.name}</span>
                                    </div>
                                    <div>
                                        <p>Дата поступления:</p>
                                        <span>{moment(status.created_at).format('DD.MM.YYYY hh:mm').toString()}</span>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                }
            </div>
        </div>
    );
}

export default OrderView;


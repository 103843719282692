import React, {useEffect, useState} from 'react';
import {CustomInput} from "../helpers/common";
import {Link, useNavigate} from "react-router-dom";
import {ClientsService} from "../services/ClientsService";
import {OrdersService} from "../services/OrdersService";

function SortingAdd() {
    const navigate = useNavigate()
    const [validation, setValidation] = useState({
        requestIsSend: false,
        tracking_numberCheck: false,
        tracking_numberHelperText: '',
    })
    const [orderInfo, setOrderInfo] = useState({
        order_id: '',
        tracking_number: '',
        comment: '',
        quantity: '',
        code: '',
        full_name: 'Неизвестная посылка',
    })
    const customer = ClientsService.GetCustomerBySearch({search: orderInfo.code})
    const order = OrdersService.GetSortingOrderBySearch({search: orderInfo.tracking_number})
    const handleSubmit = () =>{
        setValidation({
            ...validation,
            requestIsSend: true,
        })
        let newObj:any = {...orderInfo}
        setValidation({
            ...validation,
            requestIsSend: true,
        })
        if(orderInfo.full_name === 'Неизвестная посылка'){
            delete newObj.code
            delete newObj.full_name
            newObj.attribute = 'Неизвестная посылка'
        }
        if(newObj.tracking_number === ''){
            delete newObj.tracking_number
        }
        OrdersService.UpdateOrderToSorting(newObj).then(()=>{
            // navigate('/sorting')
            window.location.reload();
        }).catch((err)=>{
            setValidation({
                ...validation,
                requestIsSend: false,
            })
        })
    }
    useEffect(()=>{
        if(!customer.loading && !customer.error){
            if(customer.result?.data.length > 0){
                setOrderInfo({
                    ...orderInfo,
                    full_name: customer.result?.data[0].full_name
                })
            }
            else {
                setOrderInfo({
                    ...orderInfo,
                    full_name: 'Неизвестная посылка'
                })
            }
        }
    },[customer.loading])
    useEffect(()=>{
        if(!order.loading && !order.error){
            if(order.result?.data.length > 0){
                setOrderInfo({
                    ...orderInfo,
                    order_id: order.result?.data[0].id,
                    comment: order.result?.data[0].comment,
                    quantity: order.result?.data[0].quantity,
                    code: order.result?.data[0].user ? order.result?.data[0].user.code : '',
                    full_name: order.result?.data[0].user ? order.result?.data[0].user.full_name : 'Неизвестная посылка'
                })
            }
            else{
                setOrderInfo({
                    ...orderInfo,
                    order_id: '',
                    comment: '',
                    quantity: '',
                    code: '',
                    full_name: 'Неизвестная посылка',
                })
            }
        }
    },[order.loading])
    return (
        <div className='componentMainWrapper'>
            <div className='w-full pb-9'>
                <div className='flex w-full flex-row justify-start items-center gap-7 mb-6'>
                    <h1 className='page__title'>Добавление посылки</h1>
                </div>
                <div className='rounded-10px shadow-default bg-white overflow-hidden w-full flex flex-col justify-start items-center'>
                    <div className='form-add__header bg-form-header w-full px-6 py-3 flex justify-between items-center'>
                        <div className='flex justify-start items-center gap-8'>
                            <p>
                                Посылка
                            </p>
                        </div>
                    </div>
                    <form onSubmit={(event)=>{
                        event.preventDefault()
                        handleSubmit()
                    }} className="form-add__body bg-white w-full px-6 py-6 flex flex-col justify-start items-center">
                        <CustomInput
                            className='text-input text-input_40px text-input_with-border'
                            type='text'
                            placeholder='Трек код:'
                            label='Трек код:'
                            error={validation.tracking_numberCheck}
                            helperText={validation.tracking_numberHelperText}
                            value={orderInfo.tracking_number}
                            onChange={(event)=>{
                                setOrderInfo({
                                    ...orderInfo,
                                    tracking_number: event.target.value
                                })
                            }}
                        />
                        <CustomInput
                            className='text-input text-input_40px text-input_with-border'
                            type='text'
                            placeholder='ФИО Пользователя:'
                            label='ФИО Пользователя:'
                            value={orderInfo.full_name}
                            readOnly={true}
                        />
                        <CustomInput
                            className='text-input text-input_40px text-input_with-border'
                            type='text'
                            placeholder='Код Пользователя:'
                            label='Код Пользователя:'
                            value={orderInfo.code}
                            onChange={(event)=>{
                                setOrderInfo({
                                    ...orderInfo,
                                    code: event.target.value
                                })
                            }}
                        />
                        <CustomInput
                            className='text-input text-input_40px text-input_with-border'
                            type='text'
                            placeholder='Количество:'
                            label='Количество:'
                            required={true}
                            value={orderInfo.quantity}
                            onChange={(event)=>{
                                setOrderInfo({
                                    ...orderInfo,
                                    quantity: event.target.value
                                })
                            }}
                        />
                        <CustomInput
                            className='text-input text-input_40px text-input_with-border'
                            type='text'
                            placeholder='Комментарий:'
                            label='Комментарий:'
                            value={orderInfo.comment}
                            onChange={(event)=>{
                                setOrderInfo({
                                    ...orderInfo,
                                    comment: event.target.value
                                })
                            }}
                        />
                        <div className='flex gap-3'>
                            <button disabled={validation.requestIsSend} type='submit' className='submit-button_blue h-10'>
                                {validation.requestIsSend
                                    ?<div style={{width: '30px', height: '30px'}} className="loader"></div>
                                    : <span>Добавить</span>
                                }
                            </button>
                            <Link to='/sorting'  className='submit-button_white h-10'>
                                <span> Отменить</span>
                            </Link>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default SortingAdd;


import React, {useEffect, useState} from 'react';
import {useNavigate} from "react-router-dom";
import {
    CustomInput,
    CustomPagination,
    CustomTable,
    FilterSearchIcon,
    OrderDeleteIcon,
    OrderEditIcon
} from "../helpers/common";
import {OrdersService} from "../services/OrdersService";
import {Pagination} from "@mui/material";

function Ransom() {
    const navigate = useNavigate()
    const [filterValue, setFilterValue] = React.useState<any>({
        count: 1,
        page: 1,
        search: '',
        update_request: false,
    })
    const mainTableColumns: any[] = [
        { field: 'code', headerName: 'Код клиента', width: '80px'},
        { field: 'id', headerName: '', hide: true, width: '80px'},
        { field: 'full_name', headerName: 'ФИО'},
        { field: 'tracking_number', headerName: 'Трек-код', width: '110px'},
        { field: 'price', headerName: 'Стоимость', width: '110px'},
        { field: 'commission', headerName: 'Комиссия', width: '100px'},
        { field: 'currency_value', headerName: 'Курс', width: '70px'},
        { field: 'total_price', headerName: 'Итог стоимость', width: '110px'},
        { field: 'status', headerName: 'Статус', width: '130px', renderCell: (params: any) => {
                return (
                    <div className="flex justify-start items-center gap-3">
                        <img src={params.status_icon} alt="icon" style={{width:'22px', height:'22px'}}/>
                        {params.status === 'inProcess' &&
                            <div className='page-table__icon-button' onClick={()=>{
                                navigate(`/ransomEdit/${params.id}`)
                            }}>
                                <OrderEditIcon/>
                            </div>
                        }
                        {params.status === 'inProcess' &&
                            <div className='page-table__icon-button' onClick={()=>{
                                OrdersService.DeleteRansom(params.id).then(()=>{
                                    setFilterValue({...filterValue, update_request: !filterValue.update_request,})
                                })
                            }}>
                                <OrderDeleteIcon/>
                            </div>
                        }
                    </div>
                )
            }},
        { field: 'status_icon', headerName: '', hide: true,},
    ];

    const [mainTableData, setMainTableData] = useState<any>([])

    const ransoms = OrdersService.GetRansoms(filterValue)

    useEffect(()=>{
        if(!ransoms.loading && !ransoms.error){
            setFilterValue((prevState: any)=>({
                ...prevState,
                count: ransoms.result?.data.total_pages,
                page: ransoms.result?.data.current_page,
            }))
            const arr: any[] = []
            for (let i=0;i<ransoms.result?.data.results.length;i++){
                arr[i] = {
                    id: ransoms.result?.data.results[i].id,
                    code: ransoms.result?.data.results[i].user ? ransoms.result?.data.results[i].user.code : '',
                    full_name: ransoms.result?.data.results[i].full_name,
                    tracking_number: ransoms.result?.data.results[i].order.tracking_number,
                    price: ransoms.result?.data.results[i].order.price,
                    commission: ransoms.result?.data.results[i].commission,
                    currency_value: ransoms.result?.data.results[i].currency_value,
                    total_price: `${ransoms.result?.data.results[i].order.price * ransoms.result?.data.results[i].order.quantity + ransoms.result?.data.results[i].commission}`,
                    status: ransoms.result?.data.results[i].order.status.status,
                    status_icon: ransoms.result?.data.results[i].order.status.icon,
                }
            }
            setMainTableData(arr)
        }
    },[ransoms.loading])
    return (
        <div className='componentMainWrapper'>
            <div className='w-full pb-9'>

                <div className='flex w-full flex-row justify-between items-center gap-7 mb-6'>
                    <h1 className='page__title'>Выкуп</h1>
                    <div className='submit-button_blue h-10'
                         onClick={()=>{navigate('/ransomAdd')}}
                    >
                        Добавить выкуп
                    </div>
                </div>

                <div className='flex w-full flex-row justify-between items-center mb-6'>
                    <div className='flex w-fit flex-row justify-start items-center gap-5'>
                    </div>
                    <div className='flex w-fit flex-row justify-start items-center'>
                        <CustomInput
                            className='filter-input_search'
                            type='text'
                            placeholder='Введите трек номер'
                            value={filterValue.search}
                            onChange={(event)=>{setFilterValue({
                                ...filterValue,
                                search: event.target.value,
                                page: 1,
                            })}}
                            inputProps={
                                <div className='text-input_icon_button'>
                                    <FilterSearchIcon/>
                                </div>
                            }
                        />
                    </div>
                </div>

                <CustomTable
                    columns={mainTableColumns}
                    rows={mainTableData}
                    checkboxSelection={false}
                    loading={ransoms.loading}
                    footer={
                        <div className="page-table__footer flex flex-col justify-start items-center p-2.5">
                            <Pagination
                                color="primary"
                                count={filterValue.count}
                                page={filterValue.page}
                                onChange={(event,value)=>{
                                    setFilterValue({
                                        ...filterValue,
                                        page: value,
                                    })
                                }}
                            />
                        </div>
                    }
                />
            </div>
        </div>
    );
}

export default Ransom;


import {$axios} from "../http";
import {useAsync} from "react-async-hook";
import {CreateCleanSearchParams} from "../helpers/helpers";
import {createSearchParams} from "react-router-dom";

export const HistoryService = {
    GetBalanceAddHistory(searchParamsObj: any) {
        return useAsync(async () => {
            return await $axios.get('/admin/clients/replenishment/history/' + CreateCleanSearchParams(searchParamsObj))
        }, [`?${createSearchParams(searchParamsObj)}`])
    },
    GetPaymentHistoryList(searchParamsObj: any) {
        return useAsync(async () => {
            return await $axios.get('/admin/cash/payment/history/' + CreateCleanSearchParams(searchParamsObj))
        }, [`?${createSearchParams(searchParamsObj)}`])
    },
    GetShipmentHistoryList(searchParamsObj: any) {
        return useAsync(async () => {
            return await $axios.get('/admin/shipment/history/' + CreateCleanSearchParams(searchParamsObj))
        }, [`?${createSearchParams(searchParamsObj)}`])
    },
    GetShipmentHistoryOrders(id: string | undefined, searchParamsObj: any) {
        return useAsync(async () => {
            return await $axios.get('/admin/shipment/history/orders/' + `${id}/` + CreateCleanSearchParams(searchParamsObj))
        }, [`?${createSearchParams(searchParamsObj)}`])
    },
    async GetExcellFile(folder_id: any) {
        return await $axios.post('/admin/shipment/history/orders/download/', {
            folder_id: folder_id,
        })
    },
}
import React, {useEffect, useState} from 'react';
import {
    CustomInput,
    CustomSelect,
    CustomTable,
    FilterSearchIcon, PhotoAddIcon
} from "../helpers/common";
import queueIcon from '../assets/images/icons8-счет-24.png'
import {InputCheckForNumbers} from "../helpers/helpers";
import {BoxOfficeService} from "../services/BoxOfficeService";
import {Box, Pagination} from "@mui/material";

function BoxOffice() {
    const [filterValue, setFilterValue] = React.useState<any>({
        search: '',
        page: 1,
        count: 1,
        update_request: false,
    })
    const [validation, setValidation] = useState({
        modal_opened: false,
        requestIsSend: false,
    })
    const [paymentValues, setPaymentValues] = useState({
        priceInUsd: '0',
        priceInSom: '0',
        user_balance: '0',
        amount: '0',
        paid_by_balance: '0',
        discount: '0',
        payment_method: '',
    })
    const [selectedCashOrderId, setSelectedCashOrderId] = useState('')
    const payments = BoxOfficeService.GetPayments()
    const cashGroups = BoxOfficeService.GetCashGroups(filterValue)
    const [mainTableData, setMainTableData] = useState<any>([])
    const mainTableColumns: any[] = [
        { field: 'id', headerName: 'ID', width: '100px', hide: true},
        { field: 'user_id', headerName: 'Код клиента', width: '100px', renderCell: (params: any)=>params.user.code},
        { field: 'full_name', headerName: 'ФИО клиента', renderCell: (params: any)=>params.user.full_name },
        { field: 'orders_count', headerName: 'Количество', width: '100px' },
        { field: 'total_weight', headerName: 'Общий вес', width: '100px', renderCell: (params: any)=>{
            return(
                <div key={params.id}>
                    <input
                        key={params.id}
                        defaultValue={params.total_weight}
                        type="number"
                        onBlur={(event) => {
                            const newWeight = event.target.value;

                            BoxOfficeService.ChangeTotalWeight({
                                weight: newWeight,
                                id: params.id
                            })
                                .then((res) => {
                                    const updatedData = mainTableData.map((item: any) => {
                                        return item.id === params.id ? { ...item, total_weight: res.data.total_weight } : item;
                                    });
                                    setMainTableData(updatedData);
                                    cashGroups.execute()
                                })
                                .catch(() => {
                                    // Handle errors if needed
                                });
                        }}
                    />
                </div>
            )
            }},
        { field: '', headerName: 'Выставление счета', width: '100px', renderCell: (params: any) => {
                return (
                    <div className="flex justify-center items-center gap-5">
                        <button className='boxOffice__button' onClick={()=>{handleCalculationOfOrders(params.id)}}>
                            <img src={queueIcon} alt="icon"/>
                        </button>
                    </div>
                )
            } },
    ];

    useEffect(()=>{
        if(!cashGroups.loading && !cashGroups.error){
            setFilterValue((prevState: any)=>({
                ...prevState,
                count: cashGroups.result?.data.total_pages,
                page: cashGroups.result?.data.current_page,
            }))
            setMainTableData(cashGroups.result?.data.results)
        }
    },[cashGroups.loading])

    const submitChanges = () =>{
        if((parseInt(paymentValues.amount) + parseInt(paymentValues.discount) + parseInt(paymentValues.paid_by_balance)) < parseInt(paymentValues.priceInSom)){
            alert('Итоговая сумма меньше стоимости груза!')
        }
        else {
            setValidation({
                ...validation,
                requestIsSend: true,
            })
            BoxOfficeService.CreateCashTransaction({
                payment_method: paymentValues.payment_method,
                amount: paymentValues.amount,
                paid_by_balance: paymentValues.paid_by_balance,
                discount: paymentValues.discount,
            }, selectedCashOrderId).then((res) => {
                window.open(res.data.cheque)
                setValidation({
                    ...validation,
                    modal_opened: false,
                    requestIsSend: false,
                })
                setSelectedCashOrderId('')
                setPaymentValues({
                    ...paymentValues,
                    priceInUsd: '0',
                    priceInSom: '0',
                    user_balance: '0',
                    amount: '0',
                    paid_by_balance: '0',
                    discount: '0',
                    payment_method: '',
                })
                setFilterValue({
                    ...filterValue,
                    page: 1,
                    update_request: !filterValue.update_request,
                })
            }).catch((err) => {
                setValidation({
                    ...validation,
                    requestIsSend: false,
                })
            })
        }
    }
    const handleCalculationOfOrders = (cashOrderId: number)=>{
        setValidation({
            ...validation,
            requestIsSend: true,
        })
        setSelectedCashOrderId(cashOrderId.toString())
        BoxOfficeService.CalculateCashOrders(cashOrderId).then((res)=>{
            setPaymentValues({
                ...paymentValues,
                priceInUsd: `${res.data.total_sum_in_dollar}`,
                priceInSom: `${res.data.total_sum_in_som}`,
                user_balance: `${res.data.user_balance}`,
            })
            setValidation({
                ...validation,
                modal_opened: true,
                requestIsSend: false,
            })
        }).catch((err)=>{
            console.log(err)
            setValidation({
                ...validation,
                requestIsSend: false,
            })
        })
    }

    return (
        <div className='componentMainWrapper'>
            <div className='w-full pb-9'>
                <div className='flex w-full flex-row justify-between items-center gap-7 mb-6'>
                    <h1 className='page__title'>Касса</h1>
                </div>

                <div className='flex w-full flex-row justify-between items-center mb-6'>
                    <div className='flex w-fit flex-row justify-start items-center gap-5'>
                    </div>
                    <div className='flex w-fit flex-row justify-start items-center'>
                        <CustomInput
                            className='filter-input_search'
                            type='text'
                            placeholder='Введите код клиента'
                            value={filterValue.search}
                            onChange={(event)=>{
                                // setSelectedRowsId([])
                                setFilterValue({
                                    ...filterValue,
                                    search: event.target.value,
                                    page: 1,
                                })
                            }}
                            inputProps={
                                <div className='text-input_icon_button'>
                                    <FilterSearchIcon/>
                                </div>
                            }
                        />
                    </div>
                </div>

                {/* таблица */}
                <CustomTable
                    columns={mainTableColumns}
                    rows={mainTableData}
                    checkboxSelection={false}
                    multipleSelect={false}
                    loading={cashGroups.loading}
                    footer={
                        <div className="page-table__footer flex flex-col justify-start items-center p-2.5">
                            <div className='w-full flex justify-between items-center mt-2.5 mr-2.5'>
                                <div>

                                </div>
                                <div className='flex justify-center items-center gap-5'>
                                </div>
                            </div>
                            <Pagination
                                color="primary"
                                count={filterValue.count}
                                page={filterValue.page}
                                onChange={(event,value)=>{
                                    setFilterValue({
                                        ...filterValue,
                                        page: value,
                                    })
                                }}
                            />
                        </div>
                    }
                />
            </div>
            {validation.modal_opened &&
                <div onClick={(e) => {
                    e.preventDefault()
                    e.stopPropagation()
                    setValidation({...validation,modal_opened: false})
                }}
                     className='personal-password-edit__modal fixed top-0 left-0 z-999 w-full h-full flex justify-center items-center bg-white-opacity'
                >
                    <form onSubmit={(e) => {e.preventDefault(); submitChanges()}}
                          onClick={(e) => {e.stopPropagation()}}
                          style={{maxWidth: '620px', width: '100%'}}
                          className='relative flex flex-col bg-white justify-start items-center px-14 py-8 rounded-10px shadow-default'
                    >
                        <div onClick={()=>{setValidation({...validation, modal_opened: false})}}
                             className='absolute right-6 top-8 rotate-45 cursor-pointer rounded-full flex justify-center items-center hover:bg-icon-button-hover'
                             style={{width: '30px', height: '30px'}}>
                            <PhotoAddIcon/>
                        </div>
                        <h1 className='mb-7'>Выставление счета</h1>
                        <div className='boxOffice__modalPrice w-full flex justify-between items-center mb-5 gap-[20px]'>
                            <span>Сумма в $: {paymentValues.priceInUsd}</span>
                            <span>Сумма в сомах: {paymentValues.priceInSom}</span>
                            <span>Баланс: {paymentValues.user_balance}</span>
                        </div>
                        <CustomInput
                            className='text-input text-input_40px text-input_with-border'
                            type='number'
                            placeholder='Наличными:'
                            required={true}
                            label='Наличными:'
                            onChange={(e)=>{setPaymentValues({...paymentValues, amount: InputCheckForNumbers(e.target.value)})}}
                            value={paymentValues.amount}
                        />
                        <CustomSelect
                            className='default-select default-select_40px default-select_with-border'
                            placeholder='Вид оплаты:'
                            label='Вид оплаты:'
                            required={true}
                            value={paymentValues.payment_method}
                            onChange={(event)=>{
                                setPaymentValues({
                                    ...paymentValues,
                                    payment_method: event.target.value,
                                })
                            }}
                        >
                            <option value=""></option>
                            {!payments.loading && !payments.error && payments.result?.data.map((payment: any, i:number)=>(
                                <option key={i} value={payment.id}>{payment.name}</option>
                            ))}
                        </CustomSelect>
                        <CustomInput
                            className='text-input text-input_40px text-input_with-border'
                            type='number'
                            placeholder='Балансом:'
                            required={true}
                            label='Балансом:'
                            value={paymentValues.paid_by_balance}
                            onChange={(e)=>{setPaymentValues({...paymentValues, paid_by_balance: e.target.value <= paymentValues.user_balance ? InputCheckForNumbers(e.target.value) : paymentValues.paid_by_balance})}}
                        />
                        <CustomInput
                            className='text-input text-input_40px text-input_with-border'
                            type='number'
                            placeholder='Скидка:'
                            required={true}
                            label='Скидка:'
                            value={paymentValues.discount}
                            onChange={(e)=>{setPaymentValues({...paymentValues, discount: InputCheckForNumbers(e.target.value)})}}
                        />
                        <button type='submit' disabled={validation.requestIsSend} className='submit-button_blue h-10'>
                            {validation.requestIsSend
                                ? <div className="loader"></div>
                                : <span>Выставить счет</span>
                            }
                        </button>
                    </form>
                </div>
            }
        </div>
    );
}

export default BoxOffice;


import React from "react";
import {createSearchParams, Navigate} from "react-router-dom";
import {getCookie} from 'typescript-cookie'

function CreateCleanSearchParams(params: any){
    const newSearchObj = {...params}
    const keys = Object.keys(newSearchObj)
    for(let i=0;i < keys.length;i++){
        if(newSearchObj[keys[i]] === ""){
            delete newSearchObj[keys[i]]
        }
    }
    const emptyOrder = !Object.keys(newSearchObj).length;
    return emptyOrder ? '' : `?${createSearchParams(newSearchObj)}`
}

const RequireAuth = ({children}: {children: JSX.Element}) => {
    let access_token = getCookie('goldMark_admin_access_token')
    let refresh_token = getCookie('goldMark_admin_refresh_token')
    if (typeof access_token !== 'undefined' && typeof refresh_token !== 'undefined') {
        return children
    }
    else{
        return <Navigate to="auth" replace />
    }
}
const IfAuthed = ({children}: {children: JSX.Element}) => {
    let access_token = getCookie('goldMark_admin_access_token')
    let refresh_token = getCookie('goldMark_admin_refresh_token')

    if (typeof access_token !== 'undefined' && typeof refresh_token !== 'undefined') {
        return <Navigate to="/" replace />
    }
    else{
        return children
    }
}
function InputCheckForNumbers(value: string){
    return value === '' || value < '0' ? '0' : value[0] === '0' ? value.slice(1) : value
}
const CheckStaffPermission = ({children, listOfStaffs}: {children: JSX.Element, listOfStaffs: string[], }) => {
    let access_token: string | undefined = getCookie('goldMark_admin_access_token')
    const group: string | undefined = getCookie('goldMark_group')
    if(access_token === undefined){
        return  <Navigate to="/auth" replace />
    }
    else{
        const has_permission = listOfStaffs.find((staff: string)=> staff === group)
        if(has_permission !== undefined){
            return children
        }
        else{
            return  <Navigate to="/" replace />
        }
    }
}

function StringAvatar(name: string) {
    return `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`
}


function convertImageUrlToFile(url: any) {
    return new Promise((resolve, reject) => {
        const xhr = new XMLHttpRequest();
        xhr.open('GET', url, true);
        xhr.responseType = 'blob';

        xhr.onload = function () {
            if (xhr.status === 200) {
                const blob = new Blob([xhr.response], { type: 'image/jpeg' });
                const file = new File([blob], 'image.jpg', { type: 'image/jpeg' });
                resolve(file);
            } else {
                reject(new Error('Failed to convert image URL to file object.'));
            }
        };

        xhr.onerror = function () {
            reject(new Error('Failed to convert image URL to file object.'));
        };

        xhr.send();
    });
}

export {convertImageUrlToFile, CheckStaffPermission, InputCheckForNumbers, StringAvatar, CreateCleanSearchParams, IfAuthed, RequireAuth};


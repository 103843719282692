import React, {useEffect, useState} from 'react';
import {
    CustomInput,
    CustomPagination,
    CustomTable,
    FilterSearchIcon,
    OrderDeleteIcon, OrderEditIcon, PasswordHiddenIcon, PasswordVisibleIcon, PhotoAddIcon,
} from "../helpers/common";
import moment from "moment";
import {useNavigate} from "react-router-dom";
import {convertImageUrlToFile} from "../helpers/helpers";
import {ClientsService} from "../services/ClientsService";
import {Pagination} from "@mui/material";

function Customers() {
    const navigate = useNavigate()
    const [filterValue, setFilterValue] = React.useState<any>({
        search: '',
        page: 1,
        count: 1,
        update_request: false,
    })
    const [userValidation, setUserValidation] = useState<any>({
        codeCheck: false,
        codeHelperText: '',
        first_nameCheck: false,
        first_nameHelperText: '',
        last_nameCheck: false,
        last_nameHelperText: '',
        date_of_birthCheck:false,
        date_of_birthHelperText:'',
        emailCheck: false,
        emailHelperText: '',
        passwordCheck: false,
        passwordHelperText: '',
        phoneCheck: false,
        phoneHelperText: '',
        frontSideCheck: false,
        frontSideHelperText: '',
        backSideCheck: false,
        backSideHelperText: '',
        passwordVisible: false,
        requestIsSend: false,
        modal_opened: false,
        type: ''
    })
    const [userValues, setUserValues] = useState<any>({
        id: '',
        code: '',
        phone: '',
        email: '',
        password: '',
        confirm_password: '',
        first_name: '',
        last_name: '',
        date_of_birth: '',
        passport_front_side: new File([""], ""),
        passport_back_side: new File([""], ""),
    })

    const handleDataClear = ()=>{
        setUserValues({
            code: '',
            phone: '',
            email: '',
            password: '',
            confirm_password: '',
            otp: '',
            first_name: '',
            last_name: '',
            date_of_birth: '',
            passport_front_side: new File([""], ""),
            passport_back_side: new File([""], ""),
        })
        setUserValidation({
            codeCheck: false,
            codeHelperText: '',
            first_nameCheck: false,
            first_nameHelperText: '',
            last_nameCheck: false,
            last_nameHelperText: '',
            date_of_birthCheck:false,
            date_of_birthHelperText:'',
            emailCheck: false,
            emailHelperText: '',
            passwordCheck: false,
            passwordHelperText: '',
            phoneCheck: false,
            phoneHelperText: '',
            frontSideCheck: false,
            frontSideHelperText: '',
            backSideCheck: false,
            backSideHelperText: '',
            passwordVisible: false,
            requestIsSend: false,
            modal_opened: false,
            type: ''
        })
    }

    const submitStaffCreate = () =>{
        setUserValidation({
            ...userValidation,
            requestIsSend: true,
        })
        if(userValidation.type === 'edit'){
            ClientsService.UpdateClient({...userValues}, userValues.id).then((res)=>{
                setFilterValue({
                    ...filterValue,
                    update_request: !filterValue.update_request
                })
                handleDataClear()
            })
        }
        if(userValidation.type === 'add'){
            ClientsService.CreateClient(userValues).then((res)=>{
                setFilterValue({
                    ...filterValue,
                    update_request: !filterValue.update_request
                })
                handleDataClear()
            }).catch((err)=>{
                if(err.response.data.code){
                    setUserValidation({
                        ...userValidation,
                        codeCheck: true,
                        codeHelperText: err.response.data.code[0],
                        requestIsSend: false,
                    });
                }
                if(err.response.data.email){
                    setUserValidation({
                        ...userValidation,
                        emailCheck: true,
                        emailHelperText: err.response.data.email[0],
                        requestIsSend: false,
                    });
                }
                if(err.response.data.phone){
                    setUserValidation({
                        ...userValidation,
                        phoneCheck: true,
                        phoneHelperText: err.response.data.phone[0],
                        requestIsSend: false,
                    });
                }
                if(err.response.data.password){
                    setUserValidation({
                        ...userValidation,
                        passwordCheck: true,
                        passwordHelperText: err.response.data.password[0],
                        requestIsSend: false,
                    });
                }
            })
        }
    }

    const customerList = ClientsService.GetClientsList(filterValue)
    const mainTableColumns: any[] = [
        { field: 'id', headerName: 'Код клиента', hide: true, width: '100px'},
        { field: 'code', headerName: 'Код клиента', width: '100px'},
        { field: 'date_joined', headerName: 'Дата', width: '90px'},
        { field: 'username', headerName: 'ФИО клиента'},
        { field: 'email', headerName: 'Email'},
        { field: 'count_of_active_orders', headerName: 'Активные посылки', width: '170px'},
        { field: 'count_of_orders', headerName: 'Кол-во посылок', width: '170px'},
        { field: 'phone', headerName: 'Номер телефона', width: '100px'},
        { field: 'edit', headerName: '', width: '60px', renderCell: (params: any) => {
                return (
                    <div className="flex justify-center items-center gap-3">
                        <div className='page-table__icon-button' onClick={()=>{
                            setUserValidation({
                                ...userValidation,
                                modal_opened: true,
                                type: 'edit',
                            })
                            setUserValues({
                                ...userValues,
                                id: params.id
                            })
                        }}>
                            <OrderEditIcon/>
                        </div>
                        <div className='page-table__icon-button' onClick={()=>{
                            ClientsService.DeleteClient(params.id).then(()=>{
                                setFilterValue({
                                    ...filterValue,
                                    page: 1,
                                    update_request: !filterValue.update_request,
                                })
                            })
                        }}>
                            <OrderDeleteIcon/>
                        </div>
                    </div>
                )
            }},
    ];
    const [mainTableData, setMainTableData] = useState<any>([])
    useEffect(()=>{
        if(!customerList.loading && !customerList.error){
            setFilterValue((prevState: any)=>({
                ...prevState,
                count: customerList.result?.data.total_pages,
                page: customerList.result?.data.current_page,
            }))
            const data = []
            for(let i=0; i<customerList.result?.data.results.length;i++){
                data[i] = {
                    id: customerList.result?.data.results[i].id,
                    code: customerList.result?.data.results[i].code,
                    email: customerList.result?.data.results[i].email,
                    date_joined: (moment(customerList.result?.data.results[i].date_joined)).format('DD.MM.YYYY').toString(),
                    username: `${customerList.result?.data.results[i].first_name} ${customerList.result?.data.results[i].last_name}`,
                    count_of_active_orders:customerList.result?.data.results[i].count_of_active_orders,
                    count_of_orders:customerList.result?.data.results[i].count_of_orders,
                    phone: customerList.result?.data.results[i].phone,
                }
            }
            setMainTableData(data)
        }
    },[customerList.loading])
    useEffect( ()=>{
        if(userValidation.type === 'edit'){
            setUserValidation({
                ...userValidation,
                requestIsSend: true,
            })
            ClientsService.GetClient(userValues.id).then((res)=>{
                if(res.data.passport_back_side !== null && res.data.passport_front_side !== null){
                    convertImageUrlToFile(res.data.passport_front_side)
                        .then((frontSide) => {
                            convertImageUrlToFile(res.data.passport_back_side)
                                .then((backSide) => {
                                    setUserValues({
                                        ...userValues,
                                        code: res.data.code,
                                        phone: res.data.phone,
                                        email: res.data.email,
                                        first_name: res.data.first_name,
                                        last_name: res.data.last_name,
                                        date_of_birth: res.data.date_of_birth,
                                        passport_back_side: backSide,
                                        passport_front_side: frontSide,
                                        password: '',
                                        confirm_password: '',
                                    })
                                    setUserValidation({
                                        ...userValidation,
                                        requestIsSend: false,
                                    })
                                })
                                .catch((error) => {
                                    console.error('Error converting image URL to file object:', error);
                                    setUserValues({
                                        ...userValues,
                                        code: res.data.code,
                                        phone: res.data.phone,
                                        email: res.data.email,
                                        first_name: res.data.first_name,
                                        last_name: res.data.last_name,
                                        date_of_birth: res.data.date_of_birth,
                                        password: '',
                                        confirm_password: '',
                                    })
                                    setUserValidation({
                                        ...userValidation,
                                        requestIsSend: false,
                                    })
                                });
                        })
                        .catch((error) => {
                            console.error('Error converting image URL to file object:', error);
                            setUserValues({
                                ...userValues,
                                code: res.data.code,
                                phone: res.data.phone,
                                email: res.data.email,
                                first_name: res.data.first_name,
                                last_name: res.data.last_name,
                                date_of_birth: res.data.date_of_birth,
                                password: '',
                                confirm_password: '',
                            })
                            setUserValidation({
                                ...userValidation,
                                requestIsSend: false,
                            })
                        });
                }
                else{
                    setUserValues({
                        ...userValues,
                        code: res.data.code,
                        phone: res.data.phone,
                        email: res.data.email,
                        first_name: res.data.first_name,
                        last_name: res.data.last_name,
                        date_of_birth: res.data.date_of_birth,
                        password: '',
                        confirm_password: '',
                    })
                    setUserValidation({
                        ...userValidation,
                        requestIsSend: false,
                    })
                }
            }).catch((err)=>{
                setUserValidation({
                    ...userValidation,
                    requestIsSend: false,
                })
            })
        }
    },[userValidation.type])
    return (
        <div className='componentMainWrapper'>
            <div className='w-full pb-9'>
                <div className='flex w-full flex-row justify-between items-center gap-7 mb-6'>
                    <h1 className='page__title'>Клиенты</h1>
                    <div className='submit-button_blue h-10'
                         onClick={()=>{
                             setUserValidation({
                                 ...userValidation,
                                 type: 'add',
                                 modal_opened: true
                             })
                         }}
                    >
                        Добавить клиента
                    </div>
                </div>

                <div className='flex w-full flex-row justify-between items-center mb-6'>
                    <div className='flex w-fit flex-row justify-start items-center gap-5'>
                    </div>
                    <div className='flex w-fit flex-row justify-start items-center'>
                        <CustomInput
                            className='filter-input_search'
                            type='text'
                            placeholder='Введите код клиента'
                            value={filterValue.search}
                            onChange={(event)=>{setFilterValue({
                                ...filterValue,
                                search: event.target.value,
                                page: 1,
                            })}}
                            inputProps={
                                <div className='text-input_icon_button'>
                                    <FilterSearchIcon/>
                                </div>
                            }
                        />
                    </div>
                </div>

                {/* таблица */}
                <CustomTable
                    columns={mainTableColumns}
                    rows={mainTableData}
                    checkboxSelection={false}
                    loading={customerList.loading}
                    onRowDoubleClick={(params: any)=>{navigate(`/customersView/${params.id}`)}}
                    footer={
                        <div className="page-table__footer flex flex-col justify-start items-center p-2.5">
                            <Pagination
                                color="primary"
                                count={filterValue.count}
                                page={filterValue.page}
                                onChange={(event,value)=>{
                                    setFilterValue({
                                        ...filterValue,
                                        page: value,
                                    })
                                }}
                            />
                        </div>
                    }
                />
            </div>
            {userValidation.modal_opened &&
                <div onClick={(e) => {
                    e.preventDefault()
                    e.stopPropagation()
                    handleDataClear()
                }}
                     className='p-20 fixed top-0 left-0 z-999 w-full h-full flex justify-center items-center bg-white-opacity'
                >
                    <form onSubmit={(e) => {e.preventDefault(); submitStaffCreate()}}
                          onClick={(e) => {e.stopPropagation()}}
                          style={{maxWidth: '1027px', width: '100%'}}
                          className=' relative flex flex-col bg-white justify-start items-center px-20 py-8 rounded-10px shadow-default'
                    >
                        <div onClick={handleDataClear}
                             className='absolute right-6 top-8 rotate-45 cursor-pointer rounded-full flex justify-center items-center hover:bg-icon-button-hover'
                             style={{width: '30px', height: '30px'}}>
                            <PhotoAddIcon/>
                        </div>
                        <h1 className='mb-7'>
                            {userValidation.type === 'edit' && 'Изменение клиента'}
                            {userValidation.type === 'add' && 'Добавление клиента'}
                        </h1>
                        <div className='w-full grid grid-rows-none grid-cols-2 gap-x-5 overflow-scroll'>
                            <CustomInput
                                className='text-input'
                                type='text'
                                placeholder='Код клиента'
                                required={false}
                                label=''
                                readOnly={userValidation.requestIsSend}
                                value={userValues.code}
                                onChange={(event)=>{setUserValues({
                                    ...userValues,
                                    code: event.target.value,
                                })}}
                                helperText={userValidation.codeHelperText}
                                error={userValidation.codeCheck}
                            />
                            <CustomInput
                                className='text-input'
                                type='text'
                                placeholder='Имя'
                                required={true}
                                label=''
                                readOnly={userValidation.requestIsSend}
                                value={userValues.first_name}
                                onChange={(event)=>{setUserValues({
                                    ...userValues,
                                    first_name: event.target.value,
                                })}}
                                helperText={userValidation.first_nameHelperText}
                                error={userValidation.first_nameCheck}
                            />
                            <CustomInput
                                className='text-input'
                                type='text'
                                placeholder='Фамилия'
                                required={true}
                                label=''
                                readOnly={userValidation.requestIsSend}
                                value={userValues.last_name}
                                onChange={(event)=>{setUserValues({
                                    ...userValues,
                                    last_name: event.target.value,
                                })}}
                                helperText={userValidation.last_nameHelperText}
                                error={userValidation.last_nameCheck}
                            />
                            <CustomInput
                                className='text-input'
                                type='date'
                                placeholder='Дата рождения'
                                label=''
                                readOnly={userValidation.requestIsSend}
                                value={userValues.date_of_birth}
                                required={false}
                                onChange={(event)=>{setUserValues({
                                    ...userValues,
                                    date_of_birth: event.target.value,
                                })}}
                                helperText={userValidation.date_of_birthHelperText}
                                error={userValidation.date_of_birthCheck}
                            />
                            <CustomInput
                                className='text-input'
                                type='text'
                                placeholder='Передняя сторона паспорта'
                                required={false}
                                readOnly={true}
                                label=''
                                value={userValues.passport_front_side?.name}
                                helperText={userValidation.frontSideHelperText}
                                error={userValidation.frontSideCheck}
                                inputProps={
                                    <div className='text-input_icon_button'>
                                        {userValues.passport_front_side?.name === ''
                                            ?<label className='flex justify-center items-center cursor-pointer w-full h-full'>
                                                <input type="file" accept="image/*"
                                                       onChange={(event)=>{
                                                           let files: FileList | null = event.currentTarget.files;
                                                           setUserValues({
                                                               ...userValues,
                                                               passport_front_side: files?.item(0),
                                                           })
                                                       }}/>
                                                <PhotoAddIcon/>
                                            </label>
                                            :<div className='flex justify-center items-center cursor-pointer w-full h-full rotate-45'
                                                  onClick={()=>{
                                                      setUserValues({
                                                          ...userValues,
                                                          passport_front_side: new File([""], ""),
                                                      })
                                                  }}
                                            >
                                                <PhotoAddIcon/>
                                            </div>
                                        }
                                    </div>
                                }
                            />
                            <CustomInput
                                className='text-input'
                                type='text'
                                placeholder='Задняя сторона паспорта'
                                required={false}
                                readOnly={true}
                                label=''
                                value={userValues.passport_back_side?.name}
                                helperText={userValidation.backSideHelperText}
                                error={userValidation.backSideCheck}
                                inputProps={
                                    <div className='text-input_icon_button'>
                                        {userValues.passport_back_side?.name === ''
                                            ?<label className='flex justify-center items-center cursor-pointer w-full h-full'>
                                                <input type="file" accept="image/*"
                                                       onChange={(event)=>{
                                                           let files: FileList | null = event.currentTarget.files;
                                                           setUserValues({
                                                               ...userValues,
                                                               passport_back_side: files?.item(0),
                                                           })
                                                       }}/>
                                                <PhotoAddIcon/>
                                            </label>
                                            :<div className='flex justify-center items-center cursor-pointer w-full h-full rotate-45'
                                                  onClick={()=>{
                                                      setUserValues({
                                                          ...userValues,
                                                          passport_back_side: new File([""], ""),
                                                      })
                                                  }}
                                            >
                                                <PhotoAddIcon/>
                                            </div>
                                        }
                                    </div>
                                }
                            />
                            <CustomInput
                                className='text-input'
                                type='text'
                                placeholder='Номер телефона'
                                required={true}
                                readOnly={userValidation.requestIsSend}
                                label=''
                                value={userValues.phone}
                                onChange={(event)=>{setUserValues({
                                    ...userValues,
                                    phone: '+' + event.target.value.replace(/\D/g, ''),
                                })}}
                                helperText={userValidation.phoneHelperText}
                                error={userValidation.phoneCheck}
                            />
                            <CustomInput
                                className='text-input'
                                type='email'
                                placeholder='Email'
                                required={true}
                                readOnly={userValidation.requestIsSend}
                                label=''
                                value={userValues.email}
                                onChange={(event)=>{setUserValues({
                                    ...userValues,
                                    email: event.target.value,
                                })}}
                                helperText={userValidation.emailHelperText}
                                error={userValidation.emailCheck}
                            />
                            <CustomInput
                                className='text-input'
                                type={userValidation.passwordVisible ? 'text' : 'password'}
                                placeholder='Пароль'
                                label=''
                                readOnly={userValidation.requestIsSend}
                                value={userValues.password}
                                helperText={userValidation.passwordHelperText}
                                required={userValidation.type === 'add'}
                                onChange={(event)=>{setUserValues({
                                    ...userValues,
                                    password: event.target.value,
                                })}}
                                error={userValidation.passwordCheck}
                                inputProps={
                                    <div className='text-input_icon_button' onClick={()=>{
                                        setUserValidation({
                                            ...userValidation,
                                            passwordVisible: !userValidation.passwordVisible,
                                        })
                                    }}>
                                        {userValidation.passwordVisible ? <PasswordHiddenIcon/> : <PasswordVisibleIcon/>}
                                    </div>
                                }
                            />
                            <CustomInput
                                className='text-input'
                                type={userValidation.passwordVisible ? 'text' : 'password'}
                                placeholder='Подтвердите пароль'
                                label=''
                                readOnly={userValidation.requestIsSend}
                                value={userValues.confirm_password}
                                helperText={userValues.password !== userValues.confirm_password ? 'Пароли не совпадают!' : ''}
                                required={userValidation.type === 'add'}
                                onChange={(event)=>{setUserValues({
                                    ...userValues,
                                    confirm_password: event.target.value,
                                })}}
                                error={userValues.password !== userValues.confirm_password}
                                inputProps={
                                    <div className='text-input_icon_button' onClick={()=>{
                                        setUserValidation({
                                            ...userValidation,
                                            passwordVisible: !userValidation.passwordVisible,
                                        })
                                    }}>
                                        {userValidation.passwordVisible ? <PasswordHiddenIcon/> : <PasswordVisibleIcon/>}
                                    </div>
                                }
                            />
                        </div>
                        <button type='submit' disabled={userValidation.requestIsSend} className='submit-button_blue h-10'>
                            {userValidation.requestIsSend
                                ? <div className="loader"></div>
                                : userValidation.type === 'edit'
                                    ? <span>Редактировать</span>
                                    : <span>Добавить</span>
                            }
                        </button>
                    </form>
                </div>
            }
        </div>

    );
}

export default Customers;

